export default function CircleBackground({ className }) {
  return (
    <svg
      viewBox="0 0 684 684"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1_19)">
        <path
          d="M656.069 684L656.069 0L341.701 -1.37415e-05L341.7 684L656.069 684Z"
          fill="#9DCFCB"
        />
        <path
          d="M-1.49372e-05 342.276C-2.31868e-05 531.005 153.119 684 342 684C530.881 684 684 531.005 684 342.276C684 153.547 530.881 0.552361 342 0.552353C153.119 0.552345 -6.68762e-06 153.547 -1.49372e-05 342.276Z"
          fill="#9DCFCB"
        />
        <g opacity="0.3" clipPath="url(#clip1_1_19)">
          <path
            d="M371.819 88.616L371.819 594.763"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M285.09 103.899L458.342 579.514"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M208.818 147.897L534.428 535.62"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M152.208 215.302L590.901 468.376"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M122.089 297.991L620.951 385.882"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M122.089 385.986L620.951 298.095"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M152.208 468.675L590.901 215.602"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M208.818 536.08L534.428 148.357"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M285.09 580.078L458.342 104.452"
            stroke="#C4E1DD"
            strokeWidth="4"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M371.819 410.511C409.859 410.511 440.696 379.698 440.696 341.689C440.696 303.68 409.859 272.868 371.819 272.868C333.78 272.868 302.943 303.68 302.943 341.689C302.943 379.698 333.78 410.511 371.819 410.511Z"
            fill="#C4E1DD"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_19">
          <rect
            width="684"
            height="684"
            fill="white"
            transform="translate(684) rotate(90)"
          />
        </clipPath>
        <clipPath id="clip1_1_19">
          <rect
            width="503.469"
            height="510.75"
            fill="white"
            transform="translate(109.419 78.2582)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
